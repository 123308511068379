import React, { Suspense } from "react";
import Page from "../components/Page";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import StoryblokService from "../utils/storyblok-service";
import { PageContext } from "src/utils/context";
import SetCookie from "src/utils/SetCookie";
import ContentHeadingCentered from "src/components/ContentHeadingCentered";
import GetCookie from "src/utils/GetCookie";
import LoadingState from "src/components/LoadingState";

export default class PageTemplate extends React.Component {
  state = {
    story: {
      content: this.props.data.story
        ? JSON.parse(this.props.data.story.content)
        : {},
        first_published_at: this.props.data.story? this.props.data.story.first_published_at : ""
    },
    cta_referrer: null,
    gated_content_allowed: false,
    isLoading: true,
  };

  async getInitialStory() {
    // Only fetch from Storyblok in preview mode or development
    if (this.props.location.search.includes('_storyblok') || process.env.NODE_ENV === 'development') {
      StoryblokService.setQuery(this.props.location.search);
      let {
        data: { story },
      } = await StoryblokService.get(
        `cdn/stories/${this.props.data.story.full_slug}`
      );
      return story;
    }
    return null;
  }

  async fetchAuthor(Body) {
    try {
      if (!Body || !Body.length) return null;
      let Author;
      Body.forEach((obj) => {
        if (obj.Author) {
          Author = obj.Author;
        }
      });
      if (Author) {
        let response = await StoryblokService.get(
          `cdn/stories/?by_uuids=${Author}`
        );
        return response.data.stories.length
          ? response.data.stories[0].content
          : null;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching story:", error);
    }
  }

  async componentDidMount() {
    // Set loading state
    this.setState({ isLoading: true });

    let story = await this.getInitialStory();
    if (story?.content) {
      // Only fetch author if we have new content
      let Author = await this.fetchAuthor(story.content.body);
      this.setState({ 
        story,
        Author,
        isLoading: false 
      });
    } else {
      // If no new story, just remove loading state
      this.setState({ isLoading: false });
    }
    
    // Initialize Storyblok editor
    if (process.env.NODE_ENV === 'development' || this.props.location.search.includes('_storyblok')) {
      setTimeout(() => StoryblokService.initEditor(this), 200);
    }

    if (this.getGatedContentAllowed()) {
      this.setGatedContentAllowed(this.getGatedContentAllowed());
    }
    if (this.getCtaReferrer()) {
      this.setCtaReferrer(this.getCtaReferrer());
    }
  }

  getGatedContentAllowed = () => {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)gated_content_allowed\s*\s*([^;]*).*$)|^.*$/,
      "$1"
    )
      ? true
      : false;
  };

  setGatedContentAllowed = (value) => {
    SetCookie(`gated_content_allowed=${value}`);
    this.setState({ gated_content_allowed: value });
  };

  getCtaReferrer = () => {
    return GetCookie("cta_referrer");
  };

  setCtaReferrer = (value) => {
    SetCookie(`cta_referrer=${value}`);
    this.setState({ cta_referrer: value });
  };

  render() {

    const toggleContentHeadingCentered = [
      "articles",
      "webinars",
      "case_studies",
      "whitepaper",
      "use_cases",
      "ebooks",
      "customer_guides",
      "news_&_awards",
    ].includes(this.state.story.content.component);

    const metaData = {
      hasHero:
        this.state.story.content.body &&
        ["hero", "hero-custom"].includes(
          this.state.story.content.body[0]?.component
        )
          ? true
          : toggleContentHeadingCentered
          ? true
          : false,
      currentPage: this.state.story.uuid,
      breadcrumb: this.props.pageContext.breadcrumb,
      location: this.props.location,
      name: this.state.story.name
        ? this.state.story.name
        : this.props.data.story.name,
      first_published_at: this.state.story.first_published_at,
      content: this.state.story.content,
      setCtaReferrer: this.setCtaReferrer,
      ctaReferrer: this.state.cta_referrer,
      gatedContentAllowed: this.state.gated_content_allowed,
      setGatedContentAllowed: this.setGatedContentAllowed,
      author: this.state.Author,
    };


    return (
      <Suspense fallback={<LoadingState />}>
        <PageContext.Provider value={metaData}>
          {this.state.story.content.component === "OrphanPage" ? (
            <Layout metaData={metaData} page={'OrphanPage'} location={this.props.location}>
              {toggleContentHeadingCentered && (
                <ContentHeadingCentered
                  {...this.state.story}
                  name={this.props.data.story.name}
                  notBlock
                />
              )}
              <Page blok={this.state.story.content} page={'OrphanPage'}/>
            </Layout>
          ) : (
            <Layout metaData={metaData} location={this.props.location}>
              {toggleContentHeadingCentered && (
                <ContentHeadingCentered
                  {...this.state.story}
                  name={this.props.data.story.name}
                  notBlock
                />
              )}
              <Page blok={this.state.story.content} page={'page'}/>
            </Layout>
          )}
        </PageContext.Provider>
      </Suspense>
    );
  }
}

export const query = graphql`
  query storyBySlug($fullSlug: String!) {
    story: storyblokEntry(full_slug: { eq: $fullSlug }) {
      name
      content
      full_slug
      uuid
      first_published_at
    }
  }
`;
